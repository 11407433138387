import type {
  EnumOptionsDataSourceMapping,
  GroupedEnumOptionsDataSourceMapping,
} from '@web-config-app/core';
import { getDataPointValue } from '../../utils/get-data-point-value/get-data-point-value';

/**
 * Type guard to check if the provided mapping is of type GroupedEnumOptionsDataSourceMapping.
 *
 * @param mapping - The mapping to check, which can be of type EnumOptionsDataSourceMapping, GroupedEnumOptionsDataSourceMapping, or undefined.
 * @returns True if the mapping is of type GroupedEnumOptionsDataSourceMapping, false otherwise.
 */

const isGroupedEnumOptionsDataSourceMapping = (
  mapping:
    | EnumOptionsDataSourceMapping
    | GroupedEnumOptionsDataSourceMapping
    | undefined,
): mapping is GroupedEnumOptionsDataSourceMapping =>
  typeof mapping === 'object' &&
  ['groupLabelPath', 'groupIdPath', 'optionsValuePath'].every(
    (key) =>
      !!(mapping as GroupedEnumOptionsDataSourceMapping)[
        key as keyof GroupedEnumOptionsDataSourceMapping
      ],
  );

/**
 * Replaces placeholders in the template string with corresponding values from the templateValues object.
 *
 * @param template - The template string containing placeholders in the format `{key}`.
 * @param templateValues - An object containing key-value pairs where the key corresponds to the placeholder in the template and the value is the replacement.
 * @returns The template string with placeholders replaced by their corresponding values from templateValues.
 */

const applyPlaceholderValuesToTemplate = (
  template: string,
  templateValues: {
    groupId: string;
    groupLabel?: string;
    optionValue?: string;
  },
) =>
  Object.entries(templateValues).reduce(
    (acc, [key, templateValue]) =>
      acc.replace(new RegExp(`{${key}}`, 'g'), templateValue),
    template,
  );

/**
 * Returns enumOptions derived from a data source and grouped into categories
 *
 * In order to function correctly, the data source must be an array of object that
 * contain a child property (direct or deeply nested) that itself is an array of objects
 * such that each object contains a property that will correspond to the value you want to set.
 * Optionally, you can include a property to use as a readable label for the option. The label will default
 * to the value if label is not included (or not retrieved from the data)
 *
 * Optionally, you can
 * include a property to use as a readable label for the option. The label will default
 * to the value if label is not included (or not retrieved from the data)
 */

export const getMappedDataSourceNestedEnumOptions = (
  data: any,
  mapping:
    | EnumOptionsDataSourceMapping
    | GroupedEnumOptionsDataSourceMapping
    | undefined,
) => {
  if (!isGroupedEnumOptionsDataSourceMapping(mapping)) {
    /**
     * Throwing a warning here since there's some required property that wasn't included in the mapping
     */
    // eslint-disable-next-line no-console
    console.warn(
      `DataSource mapping for groupedEnumOptions does not match expected format.`,
    );
    return undefined;
  }

  const {
    optionValuePath,
    optionLabelPath,
    optionsValuePath,
    optionValueTemplate,
    groupIdPath,
    groupLabelPath,
  } = mapping;

  const mappedData =
    optionsValuePath && Array.isArray(data)
      ? data
          .map((item) => {
            /**
             * The group label is expected to be a string name that will be the label
             * of the rendered optgroup in the select list. It will typically be the
             * entity's name
             *
             * The id is used as a unique key by react. It will typically be the entity's ID
             */
            const groupLabel = getDataPointValue(item, groupLabelPath);
            const groupId = getDataPointValue(item, groupIdPath);
            /**
             * optionsValue should be a value in the entity that contains an array of objects
             */
            const optionsValue =
              getDataPointValue(item, optionsValuePath) ?? [];

            /**
             * For each object in the optionValue array, construct an select option
             */
            const mappedOptions = optionsValue
              .map((optionItem: any) => {
                const itemLabel =
                  optionLabelPath &&
                  getDataPointValue(optionItem, optionLabelPath);
                const itemDataPointValue = getDataPointValue(
                  optionItem,
                  optionValuePath,
                );
                const itemValue = optionValueTemplate
                  ? applyPlaceholderValuesToTemplate(optionValueTemplate, {
                      groupId,
                      groupLabel,
                      optionValue: itemDataPointValue,
                    })
                  : itemDataPointValue;
                return { value: itemValue, label: itemLabel ?? itemValue };
              })
              .filter(
                (optionItem: { value?: string; label?: string }) =>
                  !!optionItem.value,
              );
            /**
             * For any entity group that contains an empty array for optionsValue, include
             * a disabled option by default
             */
            const options =
              mappedOptions.length > 0
                ? mappedOptions
                : [
                    {
                      value: `${groupId}-no-options`,
                      label: 'No available options',
                      disabled: true,
                    },
                  ];
            return { options, label: groupLabel, groupId };
          })
          .filter(({ label }) => typeof label === 'string' && label.length > 0)
      : undefined;

  return { enumOptions: mappedData };
};
